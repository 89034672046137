<template>
  <div
    id="knowledge-base-article"
  >
    <b-row>

      <!-- related question -->
      <b-col
        lg="3"
        md="5"
        order="2"
        order-md="1"
      >
        <b-card>
          <h6 class="kb-title">
            <feather-icon
              icon="InfoIcon"
              size="20"
              class="mr-50"
            />
            {{ $t('tutorial.relacionados') }}
          </h6>
          <b-list-group class="list-group-circle mt-1">
            <b-list-group-item
              v-for="(que) in conteudo.relacionados"
              :key="que.id"
              class="text-body"
              :to="{path: '/tutorial/'+que.link}"
            >
              {{ que.titulo }}
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
      <!--/ related question -->

      <b-col
        lg="9"
        md="7"
        order="1"
        order-md="2"
      >
        <b-card>

          <!-- Title -->
          <b-card-title class="mb-1">
            <feather-icon
              icon="SmartphoneIcon"
              size="21"
            />

            {{ conteudo.titulo }}
          </b-card-title>
          <!-- Content -->
          <!-- eslint-disable vue/no-v-html -->
          <div
            :class="`question-content d-flex justify-content-${windowWidth>=576 ? 'start' : 'center'}`"
            v-html="conteudo.conteudo"
          />
          <div
            :class="`mt-2 question-content d-flex justify-content-${windowWidth>=576 ? 'start' : 'center'}`"
            v-html="conteudo.descricao"
          />
          <!-- eslint-enable -->
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BListGroup, BListGroupItem, BCardTitle,
} from 'bootstrap-vue'
import store from '@/store'
import tutorialStoreModule from './tutorialStoreModule'

const TUTORIAL_STORE_MODULE_NAME = 'tutorial'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BListGroup,
    BCardTitle,
    BListGroupItem,
  },
  data() {
    return {
      conteudo: {},
    }
  },

  computed: {
    windowWidth() {
      return this.$store.state.app.windowWidth
    },
  },

  watch: {
    $route(to, from) {
      if (to.params.slugCategoria !== from.params.slugCategoria || to.params.slug !== from.params.slug) {
        this.fetchData(this.$route.params.slugCategoria, this.$route.params.slug)
      }
    },
  },

  created() {
    if (!store.hasModule(TUTORIAL_STORE_MODULE_NAME)) store.registerModule(TUTORIAL_STORE_MODULE_NAME, tutorialStoreModule)
    this.fetchData(this.$route.params.slugCategoria, this.$route.params.slug)
  },

  beforeDestroy() {
    if (store.hasModule(TUTORIAL_STORE_MODULE_NAME)) store.unregisterModule(TUTORIAL_STORE_MODULE_NAME)
  },

  methods: {
    fetchData(slugCategoria, slug) {
      store
        .dispatch('tutorial/getTutorial', { slugCategoria, slug })
        .then(response => {
          this.conteudo = response.data
        })
    },

  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
</style>
